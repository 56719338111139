<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
            <v-sheet
                elevation="2"
				rounded="0"
                height="40"
				max-width="100%"
                class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
            >
                <v-icon class="white--text mb-2">mdi-database</v-icon>
                会員情報 
            </v-sheet>
            <v-card class="ma-5 rounded" >
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                       パスワード変更 
                    </v-card-subtitle>
                    <v-spacer />
                </v-system-bar>
            </v-card>
	</v-card>
	<v-card class="ma-5">
		<v-sheet class="pa-10">
			<p class="text-h5">新しいパスワードを入力して下さい</p>
			<v-alert border="top" color="red lighten-3" elevation="2" colored-border type="info">
				<p class="mb-0">パスワードは大文字(A～Z)、小文字(a～z)、数字(0～9)、</p>
				<p class="mb-0">特殊記号(# ! @ % etc..)を含め、8文字以上の長さで設定して下さい</p>
			</v-alert>
			<div class="d-flex d-inline-flex px-5 py-2 align-center">
				<div style="width:150px">新パスワード</div>
				<div style="width:250px ">
					<v-text-field 
					:append-icon="showPass?'mdi-eye':'mdi-eye-off'"
					v-model="password" 
					:error="errMessage.length>0?true:false"
					:type="showPass?'tel':'password'"
					maxlength="15"
					@click:append="showPass=!showPass"
					placeholder="新しいパスワード"  hide-details="auto" dense outlined></v-text-field>
				</div>
				<span class="text-caption ml-3" :class="[pass1count<8?'red--text':'blue--text']" v-if="pass1count>0">文字数：{{pass1count}}文字</span>
				<span class="text-caption red--text">{{errPassword}}</span>
			</div>
			<div></div>
			<div class="d-flex d-inline-flex px-5 align-center">
				<div style="width:150px">確認</div>
				<div style="width:250px">
					<v-text-field v-model="password2" 
					maxlength="15"
					:error="errMessage.length>0?true:false"
					type="password"
					placeholder="新しいパスワードの再入力"  hide-details="auto" dense outlined></v-text-field>
				</div>
			</div>
			<div></div>
			<div class="d-flex d-inline-flex px-5" v-if="errMessage!=''">
				<div style="width:150px"></div>
				<div class="red--text">{{errMessage}}</div>
			</div>
			<div class="d-flex justify-center pt-5">
				<div style="width:500px">
				<v-btn class="btn-delete ma-2"
				:disabled="!flgPassword"
				 @click="updatePassword">パスワードを更新する</v-btn>
				<v-btn class="btn-close ma-2" @click="clickBack">ダッシュボードに戻る</v-btn>
				</div>
			</div>
		</v-sheet>
	</v-card>
	</v-container>
</template>
<script>
import { MESSAGE } from "../messages";
import { CONSTANT } from '../constant';

export default {
	data() {
		return {
			password:'',
			password2:'',
			errPassword:'',
			errPassword2:'',
			flgPassword:false,
			flgPassword2:false,

			errMessage:'',
			showPass:false,
		}
	},
	computed:{
		pass1count(){
			this.errPassword='';
			this.flgPassword=false;
			if(this.password.length>=8){
				const regex = /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i; 
				if(!regex.test(this.password)){
					this.errPassword = MESSAGE.E020;
				}else{
					this.flgPassword = true;
				}
			}
			return this.password.length;
		},
		pass2count(){

			return this.password2.length;
		}
	},
	methods:{
		async updatePassword(){
			if(this.password!==this.password2){
				this.errMessage = MESSAGE.formatByArr(MESSAGE.E021);
				this.swalAlert(MESSAGE.TITLE_ERROR, this.errMessage );
				return ;
			}

            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }

			const chkRes = await this.axios.post('/member/password-update',{password:this.password, password2:this.password2});
			if(chkRes.data.resultCode !== CONSTANT.RETURN_NORMAL){
				if(chkRes.data.resultMessage){
					let msg = '';
					for(const [key,value] of Object.entries(MESSAGE)){
						if(key===chkRes.data.resultMessage){
							msg = value;
						}
					}
					if(msg){
						this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.formatByArr(msg));
					}
					return;
				}

			}
			await this.swalAlert(MESSAGE.TITLE_CONFIRM, MESSAGE.formatByArr(MESSAGE.I002,"パスワード"), MESSAGE.ICON_SUCCESS);
			this.$router.push("/");
			return ;
		},

		/**
		 * 戻るボタン
		 */
		clickBack(){
			this.$router.push("/");
		}

	}
}
</script>